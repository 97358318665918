<template>
  <ServiceRadioField :choices="serviceSelections" v-on="$listeners" />
</template>

<script>
import {
  officeOfNotaryDivorceNL,
  officeOfNotaryHouseAndMortgageNL,
  officeOfNotaryLegalEntitiesNL,
  officeOfNotaryOtherServicesNL,
  officeOfNotaryWeddingAndCohabitationNL,
  officeOfNotaryWillAndInheritanceNL,
} from 'chimera/officeOfNotary/service'
import { Selectable } from 'chimera/all/components/models/Selectable'
import ServiceRadioField from 'chimera/all/components/form/fields/service/ServiceRadioField.vue'

export default {
  name: 'ServiceRadioFormPart',

  components: {
    ServiceRadioField,
  },

  /**
   * @returns {object}
   */
  data() {
    return {
      serviceSelections: [
        new Selectable(
          'Erfenis / Testament',
          'Erfenis / Testament',
          officeOfNotaryWillAndInheritanceNL.id,
        ),
        new Selectable(
          'Trouwen / Samenleven',
          'Trouwen / Samenleven',
          officeOfNotaryWeddingAndCohabitationNL.id,
        ),
        new Selectable(
          'Onroerend Goed',
          'Onroerend Goed',
          officeOfNotaryHouseAndMortgageNL.id,
        ),
        new Selectable(
          'Rechtspersonen',
          'Rechtspersonen',
          officeOfNotaryLegalEntitiesNL.id,
        ),
        new Selectable('Scheiden', 'Scheiden', officeOfNotaryDivorceNL.id),
        new Selectable('Overig', 'Overig', officeOfNotaryOtherServicesNL.id),
      ],
    }
  },
}
</script>
